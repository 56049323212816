import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

const endpoints = [
  'https://api.thegraph.com/subgraphs/name/shahzeb8285/thedarkknightanalytics',
  'https://api.thegraph.com/subgraphs/name/shahzeb8285/dark-knight-two',
  'https://api.thegraph.com/subgraphs/name/shahzeb8285/dark-knight-subgraph',
]
export const client = new ApolloClient({
  link: new HttpLink({
    uri: endpoints[Math.floor(Math.random() * endpoints.length)],
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.bscgraph.org/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/matthewlilley/fantom-blocks',
  }),
  cache: new InMemoryCache(),
})
