import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Flex } from 'rebass'
import Link from '../Link'
import { RowFixed } from '../Row'
import Logo from '../../assets/logo.svg'

const TitleWrapper = styled.div`
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  z-index: 10;
`

const UniIcon = styled(Link)`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

export default function Title() {
  const history = useHistory()

  return (
    <TitleWrapper onClick={() => history.push('/')}>
      <Flex alignItems="center">
        <RowFixed>
          <UniIcon
            id="link"
            onClick={() => {
              window.location = 'https://dark.knightswap.financial/'
            }}
          >
            <img width={'165px'} src={'https://dark.knightswap.financial/images/knight/k4.svg'} alt="logo" />
          </UniIcon>
        </RowFixed>
      </Flex>
    </TitleWrapper>
  )
}
